<template>
	<div>
		<SettingsChangeNotificationsForm />
	</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: `${this.$t('nav.settings')} - ${this.$t('notifications.notifications')}`
		}
	},
	components: {
		SettingsChangeNotificationsForm: () => import('@/components/settings/SettingsChangeNotificationsForm.vue')
	},
	created() {
		this.fetchCurrentUser()
	},
	methods: {
		...mapActions('user', ['fetchCurrentUser'])
	}
}
</script>
